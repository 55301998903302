.container {
  position: relative;
}

.page {
  /* position: absolute; */
}

.div-loading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center ;
  justify-items: center;
  height: 40px;
  width: 100px;
  padding-left: 10px;
}

.div-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  gap:5px;
  min-height: 100%;
}

.root {
  background-color: #f5f5f5; /* Fondo neutro */
  transition: background-color 0.2s ease-in-out;
}

.page-enter {
  opacity: 0;
  transform: translateY(5px);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
