.button {
    color: #ed4712;
    border: 1px solid #ed4712;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    height: 30px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
  }
  
  .button-active {
    color: white;
    border: 2px solid #aaa;
    background-color: #ed4712;
    padding: 10px;
    border-radius: 5px;
    height: 30px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
  }
  
  .button:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 5px #aaaaaa;
    transform: translateY(-0.5px);
    cursor: pointer;
  }
  
  .button-active:hover {
    cursor: pointer;
  }
  
  .root {
    width: 100%;
  }
  