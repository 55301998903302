.logo {
    position:relative;
    /* top:-6px; */
    width: 35px;
    height: 35px;
    /* filter: drop-shadow(0 0 3px gray); */
    transition: all 0.3s ease-out;
}

.logo:hover{
    transform: scale(1.02);
}

.toolbar {
    display:flex;
    justify-Content:space-between;
    background: rgb(0,37,94);
    background: linear-gradient(90deg, rgba(0,37,94,1) 0%, rgba(0,48,122,1) 35%, rgba(0,73,185,1) 100%);
    max-height: 70px;
}

.avatar{
    position: relative;
    left: 15px;
    top: 0px;
}

.avatar-contain{
    /* background-image: url("../images/pestanaverde.png"); */
    /* background-repeat: no-repeat; */
    /* background-size: 100% 100%; */
}