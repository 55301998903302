.nested {
  padding:0px;
  margin:5px;
  color:#fff;
}

.listit {
  color:#0E3B5F;
}

.lisact {
  color:#fff;
}

.listot{
  color:#0E3B5F;
}

.navlink {
  text-decoration: none; 
  width:100%; 
  color:#0E3B5F;
  padding: 0px;
}

.navlinkA {
  text-decoration: none; 
  width:100%; 
  background-color: #0E3B5F;
  color:#fff;
  height:100%;
  padding: 0px;
}

.icon-drawer{
  color:unset;
}

.list-item-button{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height:55px;
}

.list-item-text{
  font-size: 10px;
  font-weight: bolder;
}

.listItem {
  background-color: white;
  color: var(--color-primary);
}

.listItemActive {
  background: linear-gradient(
    180deg,
    rgb(225, 94, 94) 0%,
    rgb(227, 85, 38) 35%,
    rgb(177, 67, 34) 1000%
  );
  color: white;
}

.listItemOpen {
  background-color: #A9A9A9;
  color: white;
}