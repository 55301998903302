.login-root{
    height: 100vh;
    width: 100vw;
    padding:20px;
    box-shadow: 9px 9px 5px -2px rgba(0,0,0,0.59);
    
}

.login-sub-root{
    min-height: 500px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.951);
}

.login-cenefa{
    max-height: 170px;
    margin:0px;
    padding:10px;
    width: 100%;
    display:flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /* align-content: center; */
}


.logo-kod{
    transition: all 0.5s ease-out;
}

.logo-kod:hover{
    transform: scale(1.05);
    cursor:pointer;
    
}
