.custom-descriptions {
    display: flex;
    flex-wrap: wrap;
    margin:5px;
  }
  
  .custom-item {
    margin: 2px;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex; /* Asegura que los ítems se alineen horizontalmente */
    align-items: center; /* Alinea verticalmente el contenido */
  }
  
  .custom-item-label {
    font-weight: bold;
    background-color: ghostwhite; /* Fondo color lavender */
    padding: 4px;
    border-radius: 4px;
    min-width: 120px; /* Ancho mínimo del campo label */
    display: inline-block; /* Asegura que el ancho mínimo se aplique */
  }
  
  .custom-item-content {
    margin-left: 4px;
    flex: 1; /* Permite que el contenido use el espacio restante */
    overflow: hidden; /* Oculta el contenido que se desborda */
    text-overflow: ellipsis; /* Muestra puntos suspensivos si el texto se desborda */
    white-space: nowrap; /* Evita el salto de línea en el texto */
  }
  
   /* Estilo para pantallas móviles */
   @media (max-width: 768px) {
    .custom-item {
      font-size: 14px; /* Ajusta el tamaño de la fuente en pantallas más pequeñas */
    }
  
    .custom-item-label {
      font-size: 14px; /* Ajusta el tamaño de la fuente en pantallas más pequeñas */
    }
  
    .custom-item-content {
      font-size: 13px; /* Ajusta el tamaño de la fuente en pantallas más pequeñas */
    }
  }
  
  @media (max-width: 480px) {
    .custom-item {
      font-size: 12px; /* Reduce el tamaño de la fuente en pantallas muy pequeñas */
    }
  
    .custom-item-label {
      min-width: 100px; /* Reduce el ancho mínimo del label en pantallas muy pequeñas */
    }
  
    .custom-item-content {
      font-size: 11px; /* Reduce el tamaño de la fuente en pantallas muy pequeñas */
    }
  }